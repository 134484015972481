




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ChatbotAiFaq extends Vue {
  faqElement: HTMLElement | undefined = undefined;
  faqParentElement: HTMLElement | undefined = undefined;

  mounted() {
    // チャットボット(AI FAQ ページ埋め込み型)用 div タグの移動・編集
    // ユーザローカル社の AI FAQ ウィジェットを利用し、ページ内にチャットボットを埋め込むが
    // おそらく SPA に対応していないため、想定挙動を実現するためマウントの度に index.html から div タグを取得し hidden 属性を消す。
    const faq = document.getElementById('ulfaq');
    const faqParent = this.$refs.faq_parent;
    if (faq instanceof HTMLElement && faqParent instanceof HTMLElement) {
      this.faqElement = faq;
      this.faqParentElement = faqParent;
      this.faqParentElement.appendChild(this.faqElement);
      this.faqElement.removeAttribute('hidden');
      window.ul_faq_widget('init', {
        id: 'fe2908f1edd5307ea192'
      });
    }
  }

  beforeDestroy() {
    // チャットボット(AI FAQ ページ埋め込み型)用 div タグの移動・編集
    // div タグの hidden 属性を TRUE に戻す。
    if (this.faqElement && this.faqParentElement) {
      document.body.appendChild(this.faqElement);
      this.faqElement.setAttribute('hidden', 'true');
      while (this.faqElement.firstChild) {
        this.faqElement.removeChild(this.faqElement.firstChild);
      }
    }
  }
}
